import {
  Component,
  ElementRef,
  Renderer2,
  computed,
  effect,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { IDeviceInfo } from '@desquare/interfaces';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslatePipe, NgbProgressbarModule],
  selector: 'app-device-cpu',
  template: `
    @defer (on viewport) {
      @if (cpuInPercent() && cpuInPercent() > 0) {
        <div #badge style="min-width: 3rem" class="px-1 badge">
          <span> {{ cpuInPercent() + '%' }}</span>
        </div>
      } @else {
        <span class="text-secondary">
          {{ 'NOT_AVAILABLE' | translate }}
        </span>
      }
    } @placeholder {
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      >
        <span class="text-secondary visually-hidden">
          {{ 'LOADING' | translate }}...
        </span>
      </div>
    }
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
      }
    `,
  ],
})
export class DeviceCpuComponent {
  renderer = inject(Renderer2);
  deviceInfo = input.required<IDeviceInfo>();
  protected badge = viewChild<ElementRef>('badge');

  cpuInPercent = computed(() => Math.round(this.deviceInfo()?.cpu ?? 0));

  unusedInPercent = computed(() => 100 - this.cpuInPercent());
  renderEffect = effect(() => {
    if (this.cpuInPercent() && this.badge()?.nativeElement)
      this.renderer.setStyle(
        this.badge()?.nativeElement,
        'background',
        `linear-gradient(90deg, ${this.badgeColor(this.cpuInPercent())} ${
          this.cpuInPercent() - 1
        }%,  rgb(108 117 125) ${this.cpuInPercent()}%)`,
      );
  });

  isNumber(val: any): boolean {
    return typeof val === 'number';
  }
  badgeColor(percent: number) {
    if (percent < 75) {
      return this.hexToRgb('#178863');
    } else if (percent < 90) {
      return this.hexToRgb('#bf9a10');
    } else {
      return this.hexToRgb('#f1556c');
    }
  }
  hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `
    rgb(${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(
      result[3],
      16,
    )})
    `
      : null;
  }
}
